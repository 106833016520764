<template>
  <div class="home">
    <p>Currently on develop.</p>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  }
}
</script>

<style>
.home {
  margin: auto;
}
</style>
