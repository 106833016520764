<template>
  <div id="nav">
    <div class="titles-div">
      <!-- <router-link to="/home">Home</router-link> | -->
      <router-link to="/">Artworks</router-link>
    </div>
    <div class="nav-icons">
      <a href="https://twitter.com/mondodicromo_">
        <img class="icon" src="./assets/twitter.png" alt="">
      </a>
      <a href="https://www.instagram.com/marcosiraola/">
        <img class="icon" src="./assets/instagram.png" alt="">
      </a>
    </div>
  </div>
  <div class="main">
    <router-view/>
  </div>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@300&display=swap');

body {
  margin: 0 !important;
  background-color: black;
}

#app {
  color: #2c3e50;
  background-color: black;
  font-family: IBM Plex Mono;
  color: white;
  height: 100vh;
  margin: 0 auto;
}

#nav {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#nav a {
  font-weight: bold;
  color: white;
  text-decoration: none;
  font-size: 15px;
}

#nav a.router-link-exact-active {
  border-bottom: 1px solid white;
  color: white;
  font-size: 15px;
}

.titles-div {
  margin-top: 5px;
}

.nav-icons {

}

.icon {
  width: 30px;
  height: 30px;
  margin: 5px;
  background-color: white;
  border-radius: 20px;
}

.main {
  text-align: center;
}

</style>
